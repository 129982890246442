function loadScript(path){
  if(path.startsWith('/static/')) {
    const relativePath = path.split('/static/')[1]
    const urlWithHash = window.staticUrlsWithHashes[relativePath]
    // console.log(path,urlWithHash)
    if(urlWithHash) {
      if(path.startsWith('/static/js/')) {
        path = `/static/${urlWithHash}`
        if(!window.unminified) {
          if(!window.supportsES2016) {
            const minifiedUrl = path.split(/\.js$/).join('.min.js')
            path = minifiedUrl
          }
          else {
            const minifiedUrl = path.split(/\.js$/).join('.es2016.min.js')
            path = minifiedUrl
          }
        }
      }
    }
  }
  let script = document.querySelector(`script[src="${path}"]`)
  return new Promise((resolve,reject) => {
    if(!script){
      script = document.createElement('script')
      script.src = path
      document.head.appendChild(script)
    }
    if(script.hasAttribute('data-loaded')){
      resolve()
      return;
    }
    if(script.hasAttribute('data-failed')){
      reject()
      return;
    }
    script.addEventListener('load',function(){
      script.setAttribute('data-loaded','')
      resolve()
    })
  })
}

window.isEs5 = false
window.loadScriptPrefix = ''
try { 
  eval('"use strict"; class foo {}; const a = "hoge";'); 
  window.supportsES6 = true
} 
catch (e) { 
  window.isEs5 = true
}
try {
  eval('function bar(){};(async function foo(){await bar();})();')
  window.supportsES2016 = true
}
catch(e) {
}

if(!window.isEs5) {
  document.documentElement.classList.add('es6')
}

if(window.location.search.includes('unminified')) {
  window.unminified = true
}

loadScript('/static/js/main-async.js')

loadScript('/static/third-party/autotrack/v2.0.1/autotrack.js')
ga('require', 'eventTracker');
ga('require', 'outboundLinkTracker');
ga('require', 'urlChangeTracker');
